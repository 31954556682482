import { makeStyles } from 'tss-react/mui';
import StarIcon from '@mui/icons-material/Star';
import StarEmptyIcon from '@mui/icons-material/StarBorderSharp';
import classNames from 'classnames';
import React from 'react';
import { withGw2Theme } from '../utils/withGw2Theme';

const useStyles = makeStyles()((theme) => ({
  coloredStar: {
    color: theme.palette.primary.dark,
  },
  star: {
    fontSize: '1.3rem',
  },
}));

function Rating({ value, className }: { value: number; className?: string }) {
  const { classes } = useStyles();
  return (
    <span className={className}>
      {Array.from(Array(Math.min(value, 5))).map((key, index) => (
        <StarIcon
          className={classNames(classes.coloredStar, classes.star)}
          key={`filled${index.toString()}`}
        />
      ))}
      {Array.from(Array(5 - Math.min(value, 5))).map((key, index) => (
        <StarEmptyIcon
          className={classes.star}
          key={`unfilled${index.toString()}`}
        />
      ))}
    </span>
  );
}

export default withGw2Theme()(Rating);
