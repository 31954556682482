import { TextDivider } from '@discretize/react-discretize-components';
import {
  Grow,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import insertAds from '../../../utils/insertAds';
import { builds as buildsSection } from '../../../utils/sections';
import { withGw2Theme } from '../../../utils/withGw2Theme';
import { Specialization } from '../../gw2components';
import ImageCard from '../../ImageCard';
import Rating from '../../Rating';

const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const useStyles = makeStyles()((theme) => ({
  gridList: {
    '&:not(:last-child)': {
      marginBottom: `${theme.spacing(1)} !important`,
    },
  },
  card: {
    border: `2px solid inherit`,
    '&:hover': {
      border: `2px solid ${theme.palette.text.primary}`,
      transform: `scale(0.99) !important`,
    },
  },
  h5Paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 2,
    border: `1px solid ${theme.palette.divider}`,
    display: 'table',
    margin: `0 auto ${theme.spacing(2)}`,
  },
}));

const ThemedImageCard = withGw2Theme()(ImageCard);

function BuildsPage({
  data: {
    images,
    builds: { group },
  },
}: {
  data: Queries.BuildsPageQuery;
}) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [state, setState] = React.useState({ mounted: false });

  React.useEffect(() => {
    setState({ mounted: true });
  }, []);

  if (!buildsSection || !buildsSection.orderGroups || !group) {
    return null;
  }

  return (
    <>
      {buildsSection.orderGroups(group as any).map(({ fieldValue, edges }) => (
        <Fragment key={fieldValue}>
          <TextDivider>
            <Paper className={classes.h5Paper} elevation={0}>
              <Typography variant="h5">
                {`${fieldValue} ${buildsSection.name}`}
              </Typography>
              <Rating value={5} />
            </Paper>
          </TextDivider>

          <ImageList
            cols={isSmall ? 1 : 2}
            gap={40}
            rowHeight="auto"
            className={classes.gridList}
          >
            {insertAds(
              buildsSection.orderEdges(edges).map(
                ({
                  node: {
                    frontmatter: {
                      title,
                      date,
                      profession,
                      specialization,
                      role,
                      classification,
                    },
                    fields: { slug },
                  },
                }) => {
                  const { node } =
                    images.edges.find(
                      ({ node: { relativePath } }) =>
                        relativePath ===
                        `professions/${specialization.toLowerCase()}.png`,
                    ) ||
                    images.edges.find(
                      ({ node: { relativePath } }) =>
                        relativePath ===
                        `professions/${profession.toLowerCase()}.png`,
                    ) ||
                    {};
                  const { childImageSharp } = node || {};

                  return {
                    content: (
                      <ThemedImageCard
                        className={classes.card}
                        key={slug}
                        profession={profession}
                        link={slug}
                        title={title}
                        image={childImageSharp?.gatsbyImageData}
                        topLeft={
                          <Specialization
                            // @ts-ignore
                            name={specialization}
                          />
                        }
                        topRight={role}
                        bottomLeft={
                          classification && (
                            <div
                              style={{
                                textAlign: 'right',
                                verticalAlign: 'center',
                              }}
                            >
                              Difficulty:{' '}
                              <Rating
                                style={{ fontSize: 10 }}
                                value={classification[3]}
                              />
                            </div>
                          )
                        }
                        bottomRight={dayjs().to(dayjs(date.trim()))}
                      />
                    ),
                  };
                },
              ),
              {
                style: {
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                keyPrefix: `builds-${fieldValue}`,
                format: 'auto',
                responsive: true,
                slot: '7724795900',
                wrap: (inner) => (
                  <div
                    className="adsbygoogle"
                    style={{
                      position: 'relative',
                      width: '100%',
                      paddingTop: '56.25%',
                    }}
                  >
                    {inner}
                  </div>
                ),
              },
            ).map(({ content, isAd }, tileIndex) => (
              <Grow
                className={isAd ? 'adsbygoogle' : ''}
                // eslint-disable-next-line react/no-array-index-key
                key={tileIndex}
                in={state.mounted}
                {...(state.mounted ? { timeout: (tileIndex + 1) * 100 } : {})}
              >
                <ImageListItem>{content}</ImageListItem>
              </Grow>
            ))}
          </ImageList>
        </Fragment>
      ))}
    </>
  );
}

export default BuildsPage;
