import { graphql, PageProps } from 'gatsby';
import React from 'react';
import ArchiveWarning from '../../components/ArchiveWarning';
import BuildsPage from '../../components/pages/builds/BuildsPage';
import Layout from '../../components/Layout';
import SeoHeader from '../../components/SeoHeader';

const layout = {
  SeoProps: {
    title: 'Archived Builds',
    description:
      'Discover all the archived fractal builds. Archived builds are no longer maintained, but maybe someone still like to check them out.',
  },
  ContainerProps: {
    paper: false,
  },
};

export default function BuildsPageWrapper({
  data,
  location,
}: PageProps<Queries.BuildsArchivePageQuery>) {
  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <ArchiveWarning />
      <BuildsPage data={data} />
    </Layout>
  );
}

export const query = graphql`
  query BuildsArchivePage {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { glob: "professions/*" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 591
              height: 337
              transformOptions: { cropFocus: ENTROPY }
            )
          }
        }
      }
    }

    builds: allMdx(
      filter: {
        fields: { section: { eq: "builds" } }
        frontmatter: { hidden: { eq: false }, archive: { eq: true } }
      }
    ) {
      group(field: frontmatter___role) {
        fieldValue
        edges {
          node {
            frontmatter {
              title
              date
              profession
              specialization
              role
              classification
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`;
